import React from "react"
import { graphql } from "gatsby"
import { Box, Grid, Themed } from "theme-ui"
import LayoutGrid from "../components/layout-grid"
import Aside from "../components/aside"
import MenuAbout from "../components/menu-about"
import MenuSocial from "../components/menu-social"
import FieldsNewsArchive from "../components/fields-news-archive"
import { useMediaQuery } from 'react-responsive'
import Seo from "../components/seo"

const ArchiveNews = ({ data }) => {

  const allNewsData = data.allMarkdownRemark.edges
  const dataFragments = data.dataJson
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })

  return (
    <LayoutGrid>
      <Seo
        title={dataFragments.fragment_1}
        description={dataFragments.fragment_2}
      />
      {isDesktopOrLaptop &&
        <Aside>
          <MenuAbout />
          <MenuSocial />
        </Aside>
      }
      <Box
        as={`main`}
        className="content"
      >
        <Themed.h1>{dataFragments.fragment_1}</Themed.h1>
        <Themed.p>{dataFragments.fragment_2}</Themed.p>
        <Grid
          columns={[1, 2, 2, 2, 2, 3]}
          gap={[2, 3]}
          sx={{
            maxWidth: `100vw !important`,
          }}
        >
          {allNewsData.map(({ node, index }) => (
            <FieldsNewsArchive
              key={node.frontmatter.slug}
              title={node.frontmatter.title}
              slug={node.frontmatter.slug}
              date={node.frontmatter.date}
              language={node.frontmatter.language}
              medio={node.frontmatter.medio}
              source={node.frontmatter.source}
            />
          ))}
        </Grid>
        {isTabletOrMobileDevice &&
          <Box
            sx={{
              marginTop: 4,
            }}
          >
            <MenuAbout />
            <MenuSocial />
          </Box>
        }
      </Box>
    </LayoutGrid>
  )
}

export default ArchiveNews

export const query = graphql`
  query ArchiveNews {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(news)/"}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            language
            medio
            source
          }
        }
      }
    }
    dataJson(slug: {eq: "page-noticias"}) {
      fragment_1
      fragment_2
    }
  }
`
