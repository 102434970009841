import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Themed } from "theme-ui"

const FieldsNewsArchive = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query FieldsNewsArchive {
        dataJson(slug: {eq: "fields-news-archive"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
        }
      }
    `
  )

  const title = props.title
  const date = props.date
  const language = props.language
  const slug = props.slug
  const medio = props.medio
  const source = props.source
  const dataFragments = data.dataJson

  return(

    <Box
      sx={{
        h2: {
          fontSize: 2,
          fontWeight: `body`,
          lineHeight: `snug`,
          maxWidth: `90%`,
        },
        a: {
          borderBottom: `none`,
          color: `tertiary`,
        },
        ul: {
          listStyle: `none`,
          mb: 0,
          ml: 0,
          pl: 0,
        },
        li: {
          fontSize: 1,
          lineHeight: `none`,
          my: 1,
        }
      }}
    >
      <Themed.h2><Themed.a as={Link} to={`/${slug}`}>{title}</Themed.a></Themed.h2>
      <Themed.ul>
        <Themed.li>{dataFragments.fragment_1}: {date}</Themed.li>
        <Themed.li>{dataFragments.fragment_2}: {language}</Themed.li>
        <Themed.li>{dataFragments.fragment_3}: {medio}</Themed.li>
        <Themed.li>{dataFragments.fragment_4}: {source}</Themed.li>
      </Themed.ul>
    </Box>
  
  )

}

export default FieldsNewsArchive