import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Themed } from "theme-ui"
import { lighten } from "@theme-ui/color"
import getSlug from "speakingurl"

const MenuAbout = () => {

  const data = useStaticQuery(
    graphql`
      query MenuAbout {
        dataJson(slug: {eq: "menu-about"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
        }
      }
    `
  )

  const dataFragments = data.dataJson

  const contentMenu = [
    [ getSlug(dataFragments.fragment_1), dataFragments.fragment_1 ],
    [ getSlug(dataFragments.fragment_2), dataFragments.fragment_2 ],
    [ getSlug(dataFragments.fragment_3), dataFragments.fragment_3 ]
  ]

  return (
    <Box
      as='div'
      className='menu'
      sx={{
        ".menu-items": {
          margin: 0,
          padding: 0,
        },
        li: {
          listStyle: `none`,
          my: 1,
        }
      }}
    >
      <Box
        as="nav"
        aria-label="Navigation"
      >
        <Box
          as="ul"
          className="menu-items"
          sx={{
            a: {
              color: lighten(`primary`, 0.4),
              borderBottom: `none`,
              fontSize: 2,
              textDecoration: `none`,
              ":hover": {
                color: `tertiary`,
              },
            },
            "a[aria-current='page']": {
              color: `tertiary`,
            },
            "span.icon-link": {
              color: lighten(`primary`, 0.2),
              fontSize: 0,
              ml: 1,
            }
          }}
        >
          {contentMenu.map(item => (
            <Themed.li>
              <Themed.a
                as={Link}
                to={`/${item[0]}`}
                rel="bookmark"
              >
                {item[1]}<span className="icon-link">{dataFragments.fragment_4}</span>
              </Themed.a>
            </Themed.li>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default MenuAbout